.beefarm-modal {
	.title {
		display: flex;
		align-items: center;
		justify-content: space-between;
    padding: 23px 30px 0;
    box-sizing: border-box;
		span {
			font-size: 32px;
			font-weight: bold;
		}
	}
	.content {
    flex: 1;
		padding: 0 30px;
		overflow-y: auto;
		.district{
			color: #17B7E3;
			display: block;
		}
		.address{
			color: white;
			display: block;
		}
		.content-title {
			display: inline-block;
      margin: 20px 0;
			padding-left: 15px;
			position: relative;
			line-height: 27px;
			height: 27px;
			font-size: 15px;
			font-weight: bold;
			width: 100%;
			box-sizing: border-box;
			&::after {
				content: url(../../assets/beefarm/bg_flline.png);
				position: absolute;
				left: 0;
				z-index: -1;
			}
		}
		.data-row {
			display: flex;
			align-items: center;
      font-size: 15px;
      margin-bottom: 8px;
      font-weight: bold;
			.label {
        color: #17B7E3;
			}
			.value{
				color: white;
			}
			.rc-image{
				margin-right: 8px;
				margin-bottom: 8px;
			}
		}
  }
  .footer{
    height: 40px;
    line-height: 40px;
    padding-left: 30px;
    background-color: #17485E;
    color: #2590B0;
  }
}
