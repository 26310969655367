.modal-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  .modal-wrap {
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #17b7e3;
    width: 60%;
    // min-height: 60%;
    // height: 60%;
    background-color: #172130;
    .body {
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &.align-center {
        align-items: center;
      }
    }
  }
}
@keyframes slideDown {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: 40%;
  }
}
