.governance-wrapper {
  height: 100%;
  position: relative;
  .chart-legends {
    position: absolute;
    padding: 0 18px;
    bottom: 0px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    .legend-item {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      flex: 33% 0;
      .symbol {
        display: inline-block;
        width: 15px;
        height: 15px;
        border-radius: 2px;
      }
      .name{
        margin-left: 4px;
      }
      .legend-value {
        text-align: center;
        font-size: 26px;
        font-weight: bold;
      }
    }
  }
}
