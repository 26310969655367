.source-statistic {
	display: flex;
	flex-direction: column;
	// flex: 1;
	margin-bottom: 20px;
	.label-ring {
		// height: 100%;
		display: flex;
		.left-labels {
			flex: 60%;
			border-bottom: 1px solid #16495E;
			padding-bottom: 12px;
			.label-wrapper {
				width: 100%;
				margin-bottom: 12px;
				.label {
					color: #17b7e3;
					font-size: 17px;
					&::before {
						content: url(~@/assets/beefarm/icon_dian2.png);
					}
				}
				.value {
					color: white;
					font-size: 21px;
					margin-left: 12px;
				}
			}
		}
		.right-chart {
			flex: 40%;
			#ring-chart {
				height: 100%;
			}
		}
	}
	.chart {
		flex: 1;
	}
}
