.beefarm-infomation {
	display: grid;
  grid-template-columns: 1fr 1fr;
	gap: 40px 70px;
	flex: 1;

	.info-item {
		&-header {
			display: flex;
			align-items: center;
			justify-content: space-between;

			&-title {
				color: #17b7e3;
        font-size: 18px;
			}
			.action-icon {
				cursor: pointer;
				background: url(~@/assets/beefarm/icon_more.png) no-repeat;
				background-size: cover;
				width: 20px;
				height: 20px;
				// margin-left: 10px;
			}
    }
    &-content{
      font-size: 40px;
      color: white;
      // margin-top: 12px;
      .unit{
        font-size: 21px;
        margin-left: 10px;
      }
    }
	}
}
