.c-icon(@bgImg) {
  background: url(@bgImg);
}
.ContentLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  .layout-header {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    margin-bottom: 20px;

    .title {
      color: #ffd03f;
      font-size: 24px;
      font-weight: bold;
    }
    .icon {
      width: 22px;
      height: 22px;
      margin: 0 10px;
      background-size: 100%;
      background-repeat: no-repeat;
      &.ic_information {
        background-image: url(~@/assets/beefarm/ic_information.png);
      }
      &.ic_statistical {
        background-image: url(~@/assets/beefarm/ic_statistical.png);
        background-size: 90%;
      }
      &.ic_message {
        background-image: url(~@/assets/beefarm/ic_message.png);
      }
      &.ic_fengyuan {
        background-size: 90%;
        background-image: url(~@/assets/beefarm/ic_fengyuan.png);
      }
      &.ic_jiankong {
        background-image: url(~@/assets/beefarm/ic_jiankong.png);
        background-position-y: 50%;
      }
      &.ic_fengxiang {
        background-image: url(~@/assets/beefarm/ic_fengxiang.png);
        background-size: 90%;
      }
      &.ic_gongchang {
        background-image: url(~@/assets/beefarm/ic_gongchang.png);
      }
      &.ic_chonghai {
        background-image: url(~@/assets/beefarm/ic_chonghai.png);
      }
      &.ic_dongtai {
        background-image: url(~@/assets/beefarm/ic_dongtai.png);
      }
      &.ic_fyzhanshi {
        background-image: url(~@/assets/beefarm/ic_fyzhanshi.png);
      }
      &.ic_qixiang {
        background-image: url(~@/assets/beefarm/ic_qixiang.png);
      }
      &.ic_tongji {
        background-image: url(~@/assets/beefarm/ic_tongji.png);
      }
      &.ic_zhishi {
        background-image: url(~@/assets/technical/icon_zhishi.png);
      }
      &.ic_zhidao {
        background-image: url(~@/assets/technical/icon_zhidao.png);
      }
      &.ic_laiyuan {
        background-image: url(~@/assets/beefarm/ic_laiyuan.png);
        background-size: 84%;
      }
      // .c-icon("../../assets/beefarm/@iconUrl")
      // background: url('~@/assets/beefarm/@iconUrl');
    }
    .decoration {
      background: url(~@/assets/common/eeff.gif) no-repeat;
      background-size: 100% 50%;
      background-position-y: 50%;
      flex: 1;
      height: 32px;
    }

    .arrow {
      cursor: pointer;
      background: url(~@/assets/beefarm/icon_more.png) no-repeat;
      background-size: cover;
      width: 20px;
      height: 20px;
      margin-left: 10px;
      &.beefarm-monitor {
        position: relative;
        margin-left: 80px;
        &::before {
          width: 100px;
          left: -70px;
          top: -2px;
          color: #17b7e3;
          font-style: normal;
          cursor: pointer;
          position: absolute;
          content: "全部监控";
        }
      }
      &.factory {
        width: 30px;
        height: 24px;
        background: url(~@/assets/beefarm/icon_shipin_close.png) no-repeat;
        &.active {
          background: url(~@/assets/beefarm/icon_shipin_open.png) no-repeat;
        }
      }
    }
  }
  .content-layout-loading,
  .no-data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
