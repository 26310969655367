.nectar-img {
  width: 100%;
  height: 77%;
  .swiper-container {
    height: 100%;
    .swiper-slide-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}
