.video-list-page {
  padding: 55px;
  color: white;
  height: 100%;
  box-sizing: border-box;
  .nav-bar-wrapper {
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    width: 100%;

    .nav-bar {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      .total {
        color: #17b7e3;
      }
      .date,
      .time {
        color: #17b7e3;
        margin: 4px 0;
      }
      .icon-video {
        background-image: url(~@/assets/beefarm/ic_jiankong.png);
        background-position-y: 50%;
        width: 40px;
        height: 40px;
        background-size: 100%;
        background-repeat: no-repeat;
      }
      .page-title {
        color: #ffd03f;
        font-size: 24px;
        font-weight: bolder;
      }
      .pagination-wrapper {
        display: flex;
        align-items: center;
        overflow-x: auto;
        width: 80%;
        margin-left: 45px;
        .page-btn-wrapper {
          width: 100px;
          flex: none;
          padding: 5px 10px;
          margin: 0 8px;
          text-align: center;
          color: #17b7e3;
          border-color: #17b7e3;
          background-color: transparent;
          border-radius: 30px;
          border: 1px solid;
          cursor: pointer;
          &.active {
            color: white;
            border-color: #17b7e3;
            background-color: #17b7e3;
          }
        }
      }
    }
    .back {
      .back-btn {
        cursor: pointer;
        color: #999999;
        border-color: #999999;
        border-radius: 30px;
        border: 1px solid;
        padding: 8px 40px;
        padding: 6px 50px;
      }
    }
  }

  .video-list {
    margin-top: 10px;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: repeat(2, 50%);
    gap: 20px 20px;
    height: calc(100% - 150px);
  }
}
