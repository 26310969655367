.video-widget {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #53b3dc;
  position: relative;

  .info-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 10px;
    color: white;
    font-weight: normal;
    // background-color: white;
    -webkit-text-stroke: 1px #d3d3d3;
    width: 100%;
    .beefarm-name {
      font-size: 24px;
    }
    .farm-weather-info {
      margin-top: 12px;
      font-size: 18px;
      span {
        margin-right: 30px;
        i {
          font-style: normal;
        }
      }
    }
  }
  .video-wrapper {
    width: 100%;
    height: 100%;
    .video-cover {
      height: 100%;
      position: relative;
      img {
        cursor: pointer;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .ic-play {
        position: absolute;
        top: 50%;
        left: 50%;
        cursor: pointer;
        transform: translate(-50%, -50%);
        content: url(~@/assets/common/play.png);
      }
    }
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
