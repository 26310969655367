.beefarm-page {
  height: calc(100% - 110px);
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 23% repeat(7, 1fr) 23%;
  grid-template-rows: repeat(8, 9.7%);
  gap: 30px 30px;
  .grid-item {
    // border: 1px solid white;
    &.item-1 {
      grid-row-start: 1;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &.item-2 {
      grid-row-start: 1;
      grid-row-end: 6;
      grid-column-start: 2;
      grid-column-end: 9;
    }
    &.item-3 {
      grid-row-start: 1;
      grid-row-end: 3;
      grid-column-start: 9;
      grid-column-end: 10;
    }
    &.item-4 {
      grid-row-start: 4;
      grid-row-end: 7;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &.item-5 {
      grid-row-start: 3;
      grid-row-end: 5;
      grid-column-start: 9;
      grid-column-end: 10;
    }
    &.item-6 {
      grid-row-start: 7;
      grid-row-end: 9;
      grid-column-start: 1;
      grid-column-end: 2;
    }
    &.item-7 {
      grid-row-start: 6;
      grid-row-end: 9;
      grid-column-start: 2;
      grid-column-end: 5;
    }
    &.item-8 {
      grid-row-start: 6;
      grid-row-end: 9;
      grid-column-start: 5;
      grid-column-end: 9;
    }
    &.item-9 {
      grid-row-start: 5;
      grid-row-end: 7;
      grid-column-start: 9;
      grid-column-end: 10;
    }
    &.item-10 {
      grid-row-start:7;
      grid-row-end: 9;
      grid-column-start: 9;
      grid-column-end: 10;
    }
  }
}
