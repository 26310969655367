.news-detail {
  padding: 15px;
  .body {
    justify-content: initial !important;
    .title-wrapper {
      margin-top: 30px;
      padding: 0 30px;
      color: #17b7e3;
      font-size: 24px;
      font-weight: bold;
      overflow: hidden; /*超出部分隐藏*/
      white-space: nowrap; /*禁止换行*/
      text-overflow: ellipsis;
      span {
        font-weight: bold;
        margin-right: 8px;
        &.l-policy {
          color: #4effc7;
        }
        &.l-news {
          color: #17b7e3;
        }
        &.l-dynamic {
          color: #ffd75e;
        }
      }
    }
    .subtitle-wrapper {
      padding: 0 30px;
      margin-top: 12px;
      .subtitle-item {
        font-size: 14px;
        color: #2590b0;
        margin-right: 48px;
      }
    }
    .divider {
      height: 1px;
      width: 100%;
      padding: 0 30px;
      margin: 30px 0;
      box-sizing: border-box;
      background-clip: content-box;
      background-color: #1b3045;
    }
    .news-content-wrapper {
      padding: 0 30px;
      max-height: 68%;
      overflow-y: auto;
      color: #17b7e3;
      font-size: 16px;
      text-indent: 2em;
      img{
        width: 95%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
