.carousel-wrapper {
  height: 100%;
  padding-top: 20px;
  .swiper-container {
    height: 100%;
    .swiper-slide-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .swiper-pagination {
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px 15px;
      bottom: 0 !important;
      position: absolute;
      z-index: 10;
      right: 0;
    }
    .swiper-pagination-bullet {
      width: 30px;
      height: 30px;
      border-radius: 0;
      font-size: 16px;
      color: #17b7e3;
      opacity: 1;
      border: 1px solid #17b7e3;
      background-color: #1b3045;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .swiper-pagination-bullet-active {
      color: white;
      background-color: #17b7e3;
    }
    .swiper-slide .footer {
      width: 100%;
      // height: 12%;
      background: #1b3045af;
      position: absolute;
      bottom: 0;
      padding: 20px 0px 20px 20px;
      box-sizing: border-box;
      .title {
        font-size: 20px;
        display: block;
        max-width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 4px;
      }
      .time {
        color: #17b7e3;
      }
    }
  }
}
