.news-modal {
  .body {
    justify-content: initial !important;

    .news-form {
      padding: 40px;
      .submit-btn {
        margin: 24px 0 0 100px;
        .content {
          padding: 0 30px;
        }
      }
      .form-row {
        display: flex;
        align-items: center;
        &-label {
          color: #17b7e3;
          text-align: right;
          // width: 100px;
        }
        &-wrapper {
          flex: 1;
          #news_editor {
            background-color: #1b3045;
            width: 100%;
            .w-e-toolbar,
            .w-e-text-container,
            .w-e-menu-panel {
              background-color: #1b3045 !important;
            }
            .w-e-menu-panel {
              background-color: #1b3045 !important;
              border: none !important;
            }
            .w-e-text-container {
              border: none !important;
              width: calc(100% - 1px);
              .placeholder {
                color: #2590b0;
                opacity: 0.4;
              }
            }
            .w-e-toolbar {
              border: none !important;
              background-color: #29435d !important;
              .w-e-menu:hover {
                background-color: #1b3045 !important;
                i {
                  color: #999 !important;
                }
              }
            }
          }
          input.form-row-wrapper-input {
            color: white;
            font-size: 1em;
            font-weight: bold;
            background-color: #1b3045;
            outline: none;
            border: none;
            height: 40px;
            line-height: 40px;
            width: 100%;
            padding: 0 12px;
            box-sizing: border-box;
            &::placeholder {
              color: #2590b0;
              opacity: 0.4;
            }
          }
          .form-row-select {
            width: 300px;
            line-height: 40px;
            font-size: 15px;
            height: 40px;
            border: none;
            outline: none;
            background-color: #1b3045;
            .rc-select-selector {
              border: none;
              padding: 0 12px;
              box-sizing: border-box;
            }
            appearance: none;
            background: url("~@/assets/common/icon_zhankai.png") no-repeat scroll 95% center transparent;
            background-color: #1b3045;
            .rc-select-selection-item {
              color: white;
              padding: 0 12px;
              width: 80%;
            }
            .rc-select-selection-placeholder {
              padding: 0 12px;
              box-sizing: border-box;
              opacity: 0.4;
              color: #2590b0;
            }
          }
        }
      }
    }
  }
}

.form-row-select-dropdown {
  z-index: 999;
  border: none;
  box-sizing: border-box;
  background-color: #1b3045;
  box-shadow: 5px 5px 5px #1619255d;
  .rc-select-item-option-active {
    background-color: #17b7e3;
  }
  .rc-select-item-option-content {
    color: white;
  }
}
