.table-wrapper {
  flex: 1;
  width: 100%;
  position: relative;

  .pagination{
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .i-table {
    // height: 400px;
    .rc-table-body{
      border: none;
    }
    .rc-table-content {
      border: none;
    }
    .rc-table-header{
      border-color: transparent !important;
    }
    .rc-table-tbody {
      .rc-table-placeholder {
        td {
          border-top: 1px solid #2590b0;
          background-color: #19293b;
        }
      }
    }
    td {
      border: none;
      color: #2590b0;
      font-size: 15px;
      &.white {
        color: white;
      }
      &.light-number {
        color: #17b7e3;
			}
			&.table-title {
        color: #17B7E3;
				&:hover{
					color: white;
					cursor: pointer;
				}
			}
			a.operation{
        color: #17B7E3;
				cursor: pointer;
				font-weight: 1000;
			}
    }
    .rc-table-thead {
      tr {
        th {
          border: none;
          background-color: #19293b;
          color: #2590b0;
          font-size: 15px;
        }
      }
    }

    .table-row {
      &-odd {
        td {
          background-color: #172130;
        }
      }
      &-even {
        td {
          background-color: #19293b;
        }
      }
    }
  }

  .footer {
    text-align: center;
    .tip {
      display: block;
      color: #2590b0;
    }
  }
}
