// 字体大小 fs-{size} [10-60]
.loopFontSize(@i) when (@i <=60) {
  .fs-@{i} {
    font-size: @i * 1px;
  }
  .loopFontSize(@i + 2);
}
.loopFontSize(10);

.text {
  &-center {
    text-align: center;
  }
  &-left {
    text-align: left;
  }
  &-right {
    text-align: right;
  }
  &-ellipsis {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &-wrap {
    word-wrap: break-word;
  }
  &-nowrap {
    word-wrap: keep-all;
    white-space: nowrap;
  }
  // 大写字母
  &-up {
    text-transform: uppercase;
  }

  // 控制字体粗细 text-w{num} [3-7] , text-w5
  .loopFontWeight(@i) when (@i <=7) {
    &-w@{i} {
      font-weight: @i*100;
    }
    .loopFontWeight(@i+1);
  }
  .loopFontWeight(3);
}

// 盒模型 size:0-32(4 的整数倍) m-{size} ml-{size} mr-{size} mt-{size} mb-{size}
.loopBox(@i) when (@i <=10) {
  @size: @i*4;
  @sizePx: @size*1px !important;
  .m-@{size} {
    margin: @sizePx;
  }
  .mv-@{size} {
    margin: @sizePx 0;
  }
  .mh-@{size} {
    margin: 0 @sizePx;
  }
  .mt-@{size} {
    margin-top: @sizePx;
  }
  .ml-@{size} {
    margin-left: @sizePx;
  }
  .mr-@{size} {
    margin-right: @sizePx;
  }
  .mb-@{size} {
    margin-bottom: @sizePx;
  }
  .p-@{size} {
    padding: @sizePx;
  }
  .pv-@{size} {
    padding: @sizePx 0;
  }
  .ph-@{size} {
    padding: 0 @sizePx;
  }
  .pt-@{size} {
    padding-top: @sizePx;
  }
  .pl-@{size} {
    padding-left: @sizePx;
  }
  .pr-@{size} {
    padding-right: @sizePx;
  }
  .pb-@{size} {
    padding-bottom: @sizePx;
  }
  .loopBox(@i+1);
}
.loopBox(0);

.rc-image-preview-mask {
  opacity: 0.5;
}
.rc-image-preview-wrap {
  .rc-image-nav-left {
    width: 36px;
    height: 32px;
    background-image: url(~@/assets/common/arrow-left-bold.png);
  }
  .rc-image-nav-right {
    width: 28px;
    height: 32px;
    background-image: url(~@/assets/common/arrow-right-bold.png);
  }
}


.toast-notification {
  z-index: 999;
}

// 轮播表字体大小覆盖
.dv-scroll-board {
  .rows {
    .row-item {
      .ceil {
        font-size: 16px;
      }
    }
  }
}

// 轮播图 点点
.slick-dots {
  bottom: 47%;
  li {
    button {
      &::before {
        color: white;
        opacity: 1;
      }
    }
    &.slick-active {
      button {
        &::before {
          color: #17b7e3;
          opacity: 1;
        }
      }
    }
  }
}
