.ds-header {
  height: 100%;
  color: white;
  div {
    box-sizing: border-box;
  }
  .left-wrapper {
    width: 34%;
    float: left;
    height: 100%;
    background-image: url("~@/assets/beefarm/header_bg_left.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    padding-left: 1%;
    padding-right: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .name {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 2%;
      margin-right: 2%;
      span {
        color: #17b7e3;
      }
    }
  }
  .middle-wrapper {
    width: 32%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    .title-item {
      font-size: 17px;
      font-weight: bold;
      color: #17b7e3;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(~@/assets/beefarm/bg_btn_def.png);
      background-size: cover;
      width: 144px;
      height: 46px;
      cursor: pointer;
      img {
        margin-right: 10px;
      }
      &.active {
        color: white;
        font-size: 19px;
        width: 185px;
        height: 77px;
        background: url(~@/assets/beefarm/bg_btn_act.png);
        background-size: cover;
      }
    }
    .time {
      position: absolute;
      top: 95px;
      color: #17b7e3;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      .bg-timeline {
        width: 403px;
        height: 12px;
      }
      .time-text {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 7px;
        }
      }
    }
  }
  .right-wrapper {
    width: 34%;
    float: left;
    height: 100%;
    background-image: url("~@/assets/beefarm/header_bg_right.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    padding-right: 1.46vw;
    padding-left: 4vw;
    .username {
      margin-left: 6px;
      color: #17b7e3;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      position: relative;
      &:hover {
        .options {
          display: block;
        }
      }
      .options {
        display: none;
        position: absolute;
        top: 20px;
        right: -10px;
        width: 150px;
        border: 2px solid #25495e;
        background-color: #1d3040;
        ul {
          list-style: none;
          padding: 12px 0;
          li {
            color: #17b7e3;
            text-align: center;
            height: 40px;
            line-height: 40px;
            &:hover {
              color: white;
            }
          }
        }
      }
    }
    .center-button {
      flex: 1;
      .meeting {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
        background-image: url("~@/assets/beefarm/icon_meeting.png");
        background-size: 100% 100%;
        width: 90px;
        height: 36px;
      }
    }
    .area-info {
      justify-self: end;
      display: inline-flex;
      align-items: center;
      flex: 1;
      .icon-location {
        background-image: url("~@/assets/beefarm/icon_quyu.png");
        background-size: 100% 100%;
        width: 16px;
        height: 20px;
        display: inline-block;
        margin-right: 5px;
      }
      .label {
        font-size: 14px;
        color: #17b7e3;
        margin-right: 6px;
      }
      .now-area {
        font-size: 16px;
      }
    }
    .qa-news {
      display: flex;
      margin-right: 24px;
      &-content {
        width: 280px;
        background-color: rgba(22, 25, 37, 0.3);
        height: 46px;
        display: inline-flex;
        align-items: center;
        justify-content: space-around;
        span {
          cursor: pointer;
        }
        .qa {
          color: #2ad29a;
          display: inline-flex;
          align-items: flex-end;
          &::before {
            margin-right: 12px;
            content: url(~@/assets/beefarm/icon_ques.png);
          }
        }
        .news {
          display: inline-flex;
          align-items: center;
          &::before {
            margin-right: 12px;
            content: url(~@/assets/beefarm/fgline.png);
          }
          &::after {
            content: url(~@/assets/beefarm/fgline.png);
            margin-left: 12px;
          }
        }
      }
      &::before {
        content: url(~@/assets/border/bottom_left.png);
      }
      &::after {
        content: url(~@/assets/border/bottom_right.png);
      }
    }
    .exit-btn {
      cursor: pointer;
    }
  }
  .password-modal {
    .body {
      justify-content: initial;
      .password-form {
        padding: 40px 40px 20px 40px;
        .submit-btn {
          margin: 24px 0 0 100px;
          .content {
            padding: 0 30px;
          }
        }
        .form-row {
          margin-bottom: 24px;
          display: flex;
          align-items: center;
          &-label {
            color: #17b7e3;
            text-align: right;
          }
          &-wrapper {
            flex: 1;
            input.form-row-wrapper-input {
              color: white;
              font-size: 1em;
              font-weight: bold;
              background-color: #1b3045;
              outline: none;
              border: none;
              height: 40px;
              line-height: 40px;
              width: 100%;
              padding: 0 12px;
              box-sizing: border-box;
              &::placeholder {
                color: #2590b0;
                opacity: 0.4;
              }
            }
          }
        }
      }
      .hr{
        background-color: #232a3a;
        height: 2px;
        width: 100%;
        padding: 0 30px;
        background-clip: content-box;
      }
      .triangle-border-wrapper .content{
        padding: 0 20px;
      }
    }
  }
}
