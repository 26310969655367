.weather-info {
  display: grid;
  grid-template-columns: repeat(3, 33%);
  gap: 0 2px;
  .weather-item {
    // text-align: center;
    box-sizing: border-box;
    padding: 20px 15px 15px;
    background-color: #19212f;
    &-row {
      margin-bottom: 6px;
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      &.weather {
        justify-content: flex-end;
        color: #499dc2;
        &.today {
          color: white;
        }
      }
      .label {
        color: #40819d;
      }
      .value {
        color: #499dc2;
      }

      // &.today {
      //   &:nth-child(1) {
      //     .label,
      //     .value {
      //       font-size: 22px;
      //     }
      //   }
      // }
    }
    &.today {
      background-color: #1f3043;
      // padding-top: 3px;
      .value {
        color: white;
      }
    }
  }
}
