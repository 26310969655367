.line-chart-modal {
	.modal-wrap {
		.body {
			justify-content: initial;
			.line-chart-modal-content {
        display: flex;
        flex-direction: column;
				flex: 1;
				padding: 20px 30px 20px;
				.f-l-options {
					margin-left: 12px;
					margin-bottom: 20px;
					.triangle-border-wrapper {
						cursor: pointer;
						margin-right: 40px;
						color: white;
						&.ghost {
							.content {
								line-height: 36px;
								height: 38px;
								color: #17b7e3;
							}
						}
					}
				}
				.charts {
          flex: 1;
					// display: grid;
          // grid-template-columns: repeat(2, 48%);
          // grid-template-rows: repeat(2, 48%);
          // gap: 2% 4%;
          height: 100%;
          .chart-item{
            // border: 1px solid white;
            display: flex;
            flex-direction: column;
            .title{
              color: #17b7e3;
              font-size: 16px;
              display: flex;
              align-items: center;
              &::before{
                content: url(~@/assets/beefarm/icon_dian.png);
              }
              & + div{
                flex: 1;
              }
            }
          }
				}
			}
		}
	}
}
