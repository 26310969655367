.pagination {
	margin: 20px 0;
	text-align: center;
	.rc-pagination-prev,
	.rc-pagination-jump-prev,
	.rc-pagination-jump-next {
		margin-right: 1px;
		color: white;
	}
	.rc-pagination-item {
		margin: 0 1px;
		background: #1b3045;
		border: none;
		border-radius: 0;
		border: none;
		outline: none;
		line-height: 28px;
		a {
			color: #17b7e3;
		}
	}
	.rc-pagination-item-active {
		a {
			color: white;
			cursor: default;
			font-weight: bold;
		}
	}
	.rc-pagination-jump-prev button:after,
	.rc-pagination-jump-next button:after {
		color: #17b7e3;
	}
	.rc-pagination-prev button:after,
	.rc-pagination-next button:after {
		color: #17b7e3;
	}
	.rc-pagination-prev .rc-pagination-item-link,
	.rc-pagination-next .rc-pagination-item-link {
		background: #1b3045;
		outline: none;
		border: none;
		border-radius: 0;
	}
}
