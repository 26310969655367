.login-page {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	color: white;
	align-items: center;
	justify-content: center;
	.login-content {
		.title-wrapper {
			display: flex;
			align-items: center;
			.title {
				margin-left: 20px;
				color: #17b7e3;
				font-size: 43px;
				font-weight: bold;
			}
		}
	}
	.form {
		margin-top: 70px;
		display: flex;
		flex-direction: column;
		align-items: center;
		&-item {
			position: relative;
			width: 17.5vw;
			input {
				width: 100%;
				border: none;
				height: 100%;
				outline: none;
				background: transparent;
				color: white;
				font-size: 20px;
				font-weight: bold;
				padding-left: 36px;
				&::-webkit-input-placeholder {
					color: #173d50;
					font-size: 17px;
				}
			}
			.prefix-icon {
				position: absolute;
				left: 8px;
				transform: translateY(-50%);
				top: 50%;
			}
		}
		.verify-code {
			margin-bottom: 20px;
			width: 19vw;
			display: flex;
			justify-content: space-between;
			&-input {
				flex-basis: 48%;
				display: flex;
				height: 46px;
				line-height: 46px;
				&::before {
					content: url(~@/assets/border/yzm_botton_left.png);
					width: 18px;
					height: 46px;
				}
				input {
					border: none;
					border-top: 1px solid #17b7e3;
					border-bottom: 1px solid #17b7e3;
					background-color: #131620;
					outline: none;
					color: white;
					width: 100%;
					font-size: 20px;
					font-weight: bold;
					text-align: center;
					&::-webkit-input-placeholder {
						color: #173d50;
						font-size: 17px;
					}
				}
				&::after {
					content: url(~@/assets/border/yzm_botton_right.png);
					width: 18px;
					height: 46px;
				}
			}
			&-img {
				flex-basis: 48%;
				// border: 1px solid white;
				height: 46px;
				display: flex;
				&::before {
					content: url(~@/assets/border/yz_botton_left.png);
				}
				canvas {
					flex: 1;
				}
				&::after {
					content: url(~@/assets/border/yz_botton_right.png);
				}
			}
		}
		.primary-btn {
			height: 52px;
			line-height: 52px;
			color: white;
			background-color: transparent;
			border: none;
			outline: none;
			cursor: pointer;
			display: inline-flex;
			&::before {
				content: url(~@/assets/border/dl_botton_left.png);
				width: 18px;
				height: 52px;
			}
			.content {
				width: 17.5vw;
				background-color: #17b7e3;
				text-align: center;
			}
			&::after {
				content: url(~@/assets/border/dl_botton_right.png);
				width: 18px;
				height: 52px;
			}
		}
	}
}
