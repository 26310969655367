.triangle-border-wrapper {
	height: 38px;
	line-height: 38px;
	position: relative;
	box-sizing: border-box;
	display: inline-flex;
	cursor: pointer;
	&::before {
		content: url(~@/assets/border/bottom_selected_left.png);
		width: 14px;
		height: 38px;
		left: -14px;
		position: absolute;
	}
	.content {
		background-color: #17b7e3;
	}
	&::after {
		content: url(~@/assets/border/bottom_selected_right.png);
		width: 14px;
		height: 38px;
		right: -13px;
		position: absolute;
	}
	&.ghost {
		&::before {
			content: url(~@/assets/border/bottom_default_left.png);
			width: 14px;
			height: 38px;
		}
		.content {
			border-top: 1px solid #17b7e3;
			border-bottom: 1px solid #17b7e3;
			background-color: transparent;
			color: #17b7e3;
			// text-align: center;
		}
		&::after {
			content: url(~@/assets/border/bottom_default_right.png);
			width: 14px;
			height: 38px;
		}
	}
	&.login {
		height: 46px !important;
		line-height: 46px;
		&::before {
			content: url(~@/assets/border/yzm_botton_left.png);
			width: 18px;
			height: 46px;
		}
		.content {
			border-top: 1px solid #17b7e3;
			border-bottom: 1px solid #17b7e3;
			background-color: #131620;
		}
		&::after {
			content: url(~@/assets/border/yzm_botton_right.png);
			width: 18px;
			height: 46px;
		}
	}
	&.small{
		height: 26px !important;
		line-height: 26px;
		position: relative;
		&::before {
			content: url(~@/assets/border/mz_bt_select_left.png);
			position: absolute;
			left: -9px;
		}
		.content {
			border-top: 1px solid #17b7e3;
			border-bottom: 1px solid #17b7e3;
			background-color: #17b7e3;
			line-height: 24px;
			height: 24px;
		}
		&::after {
			content: url(~@/assets/border/mz_bt_select_right.png);
			position: absolute;
			right: -13px;
		}
		&.ghost{
			&::before {
				content: url(~@/assets/border/mz_bt_left.png);
			}
			.content {
				border-top: 1px solid #202f43;
				border-bottom: 1px solid #202f43;
				background-color: #202f43;
			}
			&::after {
				content: url(~@/assets/border/mz_bt_right.png);
			}
		}
	}
}
