.content-modal {
	z-index: 999;
	.body{
		&>.title{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 30px;
			height: 53px;
			color: #FFD03F;
			font-size: 26px;
			background-color: #1b3045;
			img {
				cursor: pointer;
			}
		}
	}
}
