.i-select {
	font-size: 15px;
	color: #17b7e3;
	&.rc-select {
		width: initial;
	}
	.rc-select-selector {
		border: none !important;
		background: url(~@/assets/beefarm/bg_btn_defsml.png) no-repeat;
		background-size: 100% 100%;
		height: 38px;
		line-height: 38px;
		text-align: center;
		.rc-select-selection-item {
			left: 50%;
			width: 100%;

			transform: translate(-50%, -1px);
		}
	}
	.rc-select-arrow {
		top: 47%;
		right: 10px;
		transform: translate(0, -47%);
	}
}
.i-select-dropdown {
	z-index: 999;
	// display: initial !important;
	background-color: transparent;
	border: none;
	.rc-select-item {
		font-size: 15px !important;
		color: #17b7e3;
		background: url(~@/assets/beefarm/bg_btn_defsml.png) no-repeat;
		background-size: 100% 100%;
		height: 38px;
		background-color: transparent;
		line-height: 38px;
		text-align: center;
		padding: 0;
		&.rc-select-item-option-active {
			color: white;
			text-align: center;
			background: url('~@/assets/beefarm/bg_btn_actsml.png') no-repeat;
			background-size: 100% 100%;
		}
	}
}
