.bar-wrapper {
  color: white;
  width: 100%;
  height: 26px;
  display: flex;
  margin-bottom: 12px;

  .bar-label{
    font-weight: bold;
  }
  .bar-track-wrapper {
    flex: 1;
    display: flex;
    .bar-track{
      height: 100%;
      margin: 0 12px;
    }
  }
}
