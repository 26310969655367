.full-screen {
  background-color: #161924;
  background-image: url(~@/assets/main_bg_icon.png);
  background-repeat: repeat;
  .main {
    display: flex;
    height: 100%;
    flex-direction: column;

    .main-header {
      height: 90px;
    }
  }
}

.row-item {
  &.row-hover {
    background-color: #1f3043 !important;
    .ceil {
      color: white !important;
    }
  }
}

.row-item {
  &.row-normal {
    background-color: #19212f !important;
  }
}
button{
  width: 1000px;
  height: 1000px;
  font-size: 200px;
}