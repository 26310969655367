.pie-chart-container {
  height: 100%;
  position: relative;
  .pie-title-wrapper {
    position: absolute;
    top: 24%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 40px;
    .pie-title-item {
      span {
        display: block;
        text-align: center;
      }
      .title{
        font-size: 24px;
      }
      .unit {
        color: #17b7e3;
      }
    }
  }
}
